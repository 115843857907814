import { useState } from "react";
import { EditCard, EditColumn, EditDate, EditFooter, EditGroup, EditSelect, EditTextField, GridLayout, EditLayout } from "../../components/Edit";
import LinkIcon from "../../components/form/Link";
import { ServiceStatusEdit } from "../ServiceEditNew";
import DefaultService from "./DefaultService";
import { sendNotification } from "../../components/Notification";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../axiosClient";

const ActiveCampaign = ({state,children})=>{
    const [service, setService] = state;
    const [error, setError] = useState();
    const navigate = useNavigate();

    const handleSubmit = (event) =>{

        var value = {
            
        };

        if(!event.value){
            return;
        }
        Object.entries(event.value).forEach(([key, entry])=>{
            if(key.startsWith("customFields.")){
                value.customFields??={};

                value.customFields[key.substring(13)]=entry;

            }
            else if(key==="payment_interval"||key==="discount_type"){
                value[key]=parseInt(entry);
            }
            else{
                value[key]=entry;

            }
        });

        if(value.customFields)  {
            let cfs = {

            }
            Object.entries(value.customFields).forEach(([key,entry])=>{
                cfs[key]=entry?.toString();
            });
            value.customFields=cfs;
        }
        
        axiosClient({
            method: service?.id?"PUT":"POST",
            url: `/admin/services${service?.id?'/'+service?.id:''}`,
            data: value
        })
            .then((response) => {
                setError(null);
                sendNotification("success", "Saved");
                switch(event.action){
                    case "another":
                        
                        
                        setService({});
                        window.location.reload(false);
                        return navigate(`../services/create`)
                        
                    default:
                        setService(response.data);
                        return navigate(`../services/${response.data.id}/edit`)
                }
                
            })
            .catch(setError);

    }
    return <EditLayout method={service?.id?"POST":"PUT"} onSubmit={handleSubmit} footer={<></>} shouldBlockNavigation>
                

    <EditColumn span="2">
    {children}
    <EditGroup label="Account">
        <EditCard>
            <EditTextField label="URL" disabled value={`${service?.name}.activehosted.com`}/>
            <a target="_blank" className="hover:bg-primary-600 focus:text-white hover:border-transparent hover:text-white grid items-center group justify-center font-medium tracking-tight rounded-lg border transition-colors   h-9 px-4 text-gray-800 bg-transparent border-gray-300  focus:bg-primary-700 focus:ring-offset-primary-700 focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset  focus:ring-white" href={`https://www.activecampaign.com/partner/change.php?account=${service?.name}.activehosted.com`} >
                <span>Edit billing</span>
                <span className=" justify-self-end absolute text-gray-500 group-hover:text-inherit group-focus:text-inherit"><LinkIcon className="h-5 w-5"/></span>
            </a>
        </EditCard>
    </EditGroup>
    <DefaultService disabled state={state} typeName="ActiveCampaign"/>

    </EditColumn>

    <EditColumn span="1">
    <ServiceStatusEdit disabled serviceStatus={service?.status} serviceEndDate={service?.end_date} serviceStartDate={service?.start_date} messageBoxDisplay={({customerName,endDate})=><div>
                    
                    Please confirm that {customerName} <span className="font-bold">should not get an invoice</span> for ({service?.name})  by the end of {new Date(Date.parse( endDate??new Date())).toLocaleString("en-UK", {year:"numeric",month:"long"})}.
                    </div>} />
            
    </EditColumn>
    
    </EditLayout>;
}

export default ActiveCampaign;